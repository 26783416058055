// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dk_zx";
export var servicesToolkitSection__card = "dk_zy";
export var servicesToolkitSection__icons = "dk_zz";
export var servicesToolkitSection__serviceToolkitIcon1 = "dk_zC";
export var servicesToolkitSection__serviceToolkitIcon10 = "dk_zN";
export var servicesToolkitSection__serviceToolkitIcon11 = "dk_zP";
export var servicesToolkitSection__serviceToolkitIcon12 = "dk_zQ";
export var servicesToolkitSection__serviceToolkitIcon13 = "dk_zR";
export var servicesToolkitSection__serviceToolkitIcon14 = "dk_zS";
export var servicesToolkitSection__serviceToolkitIcon15 = "dk_zT";
export var servicesToolkitSection__serviceToolkitIcon16 = "dk_zV";
export var servicesToolkitSection__serviceToolkitIcon17 = "dk_zW";
export var servicesToolkitSection__serviceToolkitIcon18 = "dk_zX";
export var servicesToolkitSection__serviceToolkitIcon19 = "dk_zY";
export var servicesToolkitSection__serviceToolkitIcon2 = "dk_zD";
export var servicesToolkitSection__serviceToolkitIcon20 = "dk_zZ";
export var servicesToolkitSection__serviceToolkitIcon3 = "dk_zF";
export var servicesToolkitSection__serviceToolkitIcon4 = "dk_zG";
export var servicesToolkitSection__serviceToolkitIcon5 = "dk_zH";
export var servicesToolkitSection__serviceToolkitIcon6 = "dk_zJ";
export var servicesToolkitSection__serviceToolkitIcon7 = "dk_zK";
export var servicesToolkitSection__serviceToolkitIcon8 = "dk_zL";
export var servicesToolkitSection__serviceToolkitIcon9 = "dk_zM";
export var servicesToolkitSection__sprite = "dk_zB";
export var servicesToolkitSection__title = "dk_z0";