// extracted by mini-css-extract-plugin
export var devopsAchievementsSection = "J_gj";
export var devopsBenefitSection = "J_f7";
export var devopsBusinessSection = "J_f5";
export var devopsDomainSection = "J_gf";
export var devopsExpertiseSection = "J_gd";
export var devopsProvideSection = "J_f8";
export var devopsResultsSection = "J_f9";
export var devopsSuccessStoriesSection = "J_gg";
export var devopsTestimonialPostSection = "J_gb";
export var devopsToolkitSection = "J_gc";
export var devopsVideoReviewSection = "J_gk";
export var devopsWhyChooseSection = "J_gh";
export var devopsWhyNeedSection = "J_f6";